import React, { useEffect, useState } from "react";
import Button from "../../Container/Button/Button";
import "./AddYourLocation.css";
import { useSelector } from "react-redux";
import { addAddress, editAddressDetails } from "../../utils/api";
import { useHistory, useLocation, useParams } from "react-router";
import { ReactComponent as Arrow } from "../../assets/arrow2.svg";
import helper from "../../utils/helper";
function AddYourLocation() {
  const location = useSelector((state) => state.location.finalLocation);
  const [nickname, setNickname] = useState(location.nickname);
  const [city, setCity] = useState(location.city);
  const [pincode, setPincode] = useState(location.pincode);
  const [country, setCountry] = useState(location.country);
  const [apartment, setApartment] = useState(location.apartment);
  const [streetAddress, setStreetAddress] = useState(location.streetAddress);
  const [province, setProvince] = useState(location.province);
  const history = useHistory();
  const params = useParams();
  const loc = useLocation();
  const locationNullCheck = loc && loc.state && loc.state.data;
  const routingText = location && location.state && location.state.text;
  const backCheck = loc && loc.state && loc.state.backflag ? false : true;
  const [backStatus, SetBackStatus] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isNickNameVaild, setisNickNameVaild] = useState(false);
  const [streetAddressVaild, setStreetAddressVaild] = useState(true);
  const [validCity, setValidCity] = useState(true);
  const [pincodeValid, setPincodeValid] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // adjust 768 to your desired breakpoint
    };

    handleResize(); // set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCity(location.city);
    setStreetAddress(location.streetAddress);
    setProvince(location.province);
    setPincode(location.pincode);
    setCountry(location.country);
    setApartment(location.apartment);
    setNickname(location.nickname);
    SetBackStatus(backCheck);
    setStreetAddressVaild(location.streetAddress);
    setisNickNameVaild(helper.isAlphaNumeric(location.nickname));
    setValidCity(helper.isNameValid(location.city));
    setPincodeValid(helper.isAlphaNumeric(location.pincode));
  }, [location, backCheck]);

  const saveAddressHandler = (e) => {
    e.preventDefault();
    if (params.id) {
      const body = {
        addressId: params.id,
        address: {
          nickname: nickname,
          streetAddress: streetAddress,
          apartment: apartment,
          city: city,
          province: province,
          country: country,
          postalcode: pincode,
          latitude: location.latitude,
          longitude: location.longitude,
        },
      };
      editAddressDetails(body)
        .then((response) => {
          if (response.data.status) {
            if (locationNullCheck === 2) {
              history.push({
                pathname: "/myaccount",
                state: { text: "location" },
              });
            } else if (locationNullCheck === 1) {
              history.push("/booking");
            } else {
              // history.push("/myaccount");
              history.push({
                pathname: "/myaccount",
                state: { text: "location" },
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const body = {
        address: {
          nickname: nickname,
          streetAddress: streetAddress,
          apartment: apartment,
          city: city,
          province: province,
          country: country,
          postalcode: pincode,
          latitude: location.latitude,
          longitude: location.longitude,
        },
      };
      addAddress(body)
        .then((response) => {
          if (response.data.status) {
            if (locationNullCheck === 2) {
              history.push({
                pathname: "/myaccount",
                state: { text: "location" },
              });
            } else if (locationNullCheck === 1) {
              history.push("/booking");
            } else {
              // history.push("/myaccount");
              history.push({
                pathname: "/myaccount",
                state: { text: "location" },
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const saveValidation = () => {
    const isValid =
      isNickNameVaild && streetAddressVaild && validCity && pincodeValid;
    return isValid;
  };

  const backwardHandler = () => {
    if (locationNullCheck === 0) {
      // history.push("/myaccount");
      history.push({
        pathname: `/myaccount`,
        state: { text: "location" },
      });
    } else if (locationNullCheck === 1) {
      history.push("/booking");
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <div className="addYourLocation-cardform">
        <div className="addYourLocation-outer">
          <div className="addYourLocation-main-header">Address</div>
          <div className="address-main">
            <div className="address-header">
              <label htmlFor="">Nick Name*</label>
            </div>
            <input
              className="addYourLocation-input"
              type="text"
              name="nickname"
              placeholder="House"
              required=""
              value={nickname}
              onChange={(e) => {
                setNickname(e.target.value);
                const isValid = helper.isAlphaNumeric(e.target.value);
                setisNickNameVaild(isValid);
              }}
            />
            {!isNickNameVaild && (
              <span className="error-message">This field cannot be empty</span>
            )}
          </div>
          <div className="address-main">
            <div className="address-header">
              <label htmlFor="">Address*</label>
            </div>
            <input
              className="addYourLocation-input"
              type="text"
              name="address"
              placeholder="Address"
              required=""
              value={streetAddress}
              onChange={(e) => {
                setStreetAddress(e.target.value);
                const isValid = e.target.value;
                setStreetAddressVaild(isValid);
              }}
            />
            {/* {!streetAddressVaild && (
              <span className="error-message">This field cannot be empty</span>
            )} */}
          </div>

          <div className="address-main">
            <div className="address-header">
              <label htmlFor="">Apartment,suit etc</label>
            </div>
            <input
              className="addYourLocation-input"
              type="text"
              name="Apartment"
              placeholder="Apartment,suit"
              required=""
              value={apartment}
              onChange={(e) => {
                setApartment(e.target.value);
              }}
            />
          </div>
          <div className="address-divider">
            <div className="address-main">
              <div className="address-header">
                <label htmlFor="">City*</label>
              </div>
              <input
                className="addYourLocation-input"
                type="text"
                name="model"
                placeholder="City"
                required=""
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  const isValid = helper.isNameValid(e.target.value);
                  setValidCity(isValid);
                }}
              />
              {!validCity && (
                <span className="error-message">
                  This field cannot be empty
                </span>
              )}
            </div>

            <div className="address-main">
              <div className="address-header">
                <label htmlFor="">Country*</label>
              </div>
              <input
                className="addYourLocation-input"
                type="text"
                name="address"
                placeholder="address"
                required=""
                readOnly
                value={country}
              />
            </div>
          </div>
          <div className="address-divider">
            <div className="address-main">
              <div className="address-header">
                <label htmlFor="">Province*</label>
              </div>
              <input
                className="addYourLocation-input"
                type="text"
                name="make"
                placeholder="Address"
                required=""
                readOnly
                value={province}
              />
            </div>
            <div className="address-main">
              <div className="address-header">
                <label htmlFor="">Postal code*</label>
              </div>
              <input
                className="addYourLocation-input"
                type="text"
                name="Pincode"
                placeholder="Pincode"
                required=""
                value={pincode && pincode}
                onChange={(e) => {
                  setPincode(e.target.value);
                  const isValid = helper.isAlphaNumeric(e.target.value);
                  setPincodeValid(isValid);
                }}
              />
              {!pincodeValid && (
                <span className="error-message">
                  This field cannot be empty
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="addYourLocation-submit"
        style={!backStatus ? { justifyContent: "flex-end" } : {}}
      >
        {backStatus ? (
          <div>
            <Button
              onClickEvent={backwardHandler}
              style={{
                gridGap: "1em",
                display: "flex",
                borderRadius: "5px",
                backgroundColor: "#17B3F0",
              }}
            >
              {!isMobile && (
                <Arrow
                  className="arrow-button"
                  style={{
                    transform: "rotate(180deg)",
                  }}
                  fill="#03577B"
                  stroke="#03577B"
                />
              )}
              <div>Back</div>
            </Button>
          </div>
        ) : (
          ""
        )}

        <div>
          <Button
            onClickEvent={saveAddressHandler}
            style={{
              gridGap: "1em",
              display: "flex",
              borderRadius: "5px",
            }}
            disabled={!saveValidation()}
          >
            <div>SAVE</div>
            {!isMobile && (
              <Arrow className="arrow-button" fill="#03577B" stroke="#03577B" />
            )}
          </Button>
        </div>
      </div>
    </>
  );
}

export default AddYourLocation;
